import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import auth_client_CtFjRSnW1L from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/winx-auth/dist/runtime/plugins/auth.client.mjs";
import payload_client_yVLowv6hDl from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src502701600/src/form.winx.ai/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_i8AMfKeYnY from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_m51yvfcbqo from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import plugin_w2VlvAEcpa from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import i18n_yfWm7jX06p from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_tLmAP220nC from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import auth_redirect_h8DTFObWwU from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/winx-auth/dist/runtime/plugins/auth-redirect.mjs";
import view_transitions_client_2LpVRIemQY from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import bugsnag_kMMx9tnXGD from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/winx-base/plugins/bugsnag.ts";
import core_mKO01y0X7r from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/winx-base/plugins/core.ts";
import directives_1uFdALNymg from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/winx-base/plugins/directives.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  auth_client_CtFjRSnW1L,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_i8AMfKeYnY,
  plugin_m51yvfcbqo,
  plugin_w2VlvAEcpa,
  i18n_yfWm7jX06p,
  plugin_tLmAP220nC,
  auth_redirect_h8DTFObWwU,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  bugsnag_kMMx9tnXGD,
  core_mKO01y0X7r,
  directives_1uFdALNymg
]