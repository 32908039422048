
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "87c9ce76-51f2-4a4a-bfa0-abb9a82f6923"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/codebuild/output/src502701600/src/form.winx.ai/app.config.ts"
import cfg1 from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/winx-base/app.config.ts"
import cfg2 from "/codebuild/output/src502701600/src/form.winx.ai/node_modules/@shuriken-ui/nuxt/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, inlineConfig)
