import { default as _91form_93AQSU3yj3IaMeta } from "/codebuild/output/src502701600/src/form.winx.ai/pages/[[research]]/[form].vue?macro=true";
import { default as deniedXfqEj27OhMMeta } from "/codebuild/output/src502701600/src/form.winx.ai/pages/[[research]]/denied.vue?macro=true";
import { default as finishGCTSvWpQh1Meta } from "/codebuild/output/src502701600/src/form.winx.ai/pages/[[research]]/finish.vue?macro=true";
import { default as interactionBJ7GBFSmt4Meta } from "/codebuild/output/src502701600/src/form.winx.ai/pages/[[research]]/interaction.vue?macro=true";
import { default as start1X1VT5O1LBMeta } from "/codebuild/output/src502701600/src/form.winx.ai/pages/[[research]]/start.vue?macro=true";
import { default as _91_91research_93_93VUu2CkmXJfMeta } from "/codebuild/output/src502701600/src/form.winx.ai/pages/[[research]].vue?macro=true";
import { default as indexzpuE8azrOrMeta } from "/codebuild/output/src502701600/src/form.winx.ai/pages/index.vue?macro=true";
import { default as loginRT5tQdtFrgMeta } from "/codebuild/output/src502701600/src/form.winx.ai/pages/login.vue?macro=true";
import { default as redirectYeI1FNxj37Meta } from "/codebuild/output/src502701600/src/form.winx.ai/pages/redirect.vue?macro=true";
export default [
  {
    name: _91_91research_93_93VUu2CkmXJfMeta?.name ?? "research",
    path: _91_91research_93_93VUu2CkmXJfMeta?.path ?? "/:research?",
    meta: _91_91research_93_93VUu2CkmXJfMeta || {},
    alias: _91_91research_93_93VUu2CkmXJfMeta?.alias || [],
    redirect: _91_91research_93_93VUu2CkmXJfMeta?.redirect,
    component: () => import("/codebuild/output/src502701600/src/form.winx.ai/pages/[[research]].vue").then(m => m.default || m),
    children: [
  {
    name: _91form_93AQSU3yj3IaMeta?.name ?? "research-form",
    path: _91form_93AQSU3yj3IaMeta?.path ?? ":form()",
    meta: _91form_93AQSU3yj3IaMeta || {},
    alias: _91form_93AQSU3yj3IaMeta?.alias || [],
    redirect: _91form_93AQSU3yj3IaMeta?.redirect,
    component: () => import("/codebuild/output/src502701600/src/form.winx.ai/pages/[[research]]/[form].vue").then(m => m.default || m)
  },
  {
    name: deniedXfqEj27OhMMeta?.name ?? "research-denied",
    path: deniedXfqEj27OhMMeta?.path ?? "denied",
    meta: deniedXfqEj27OhMMeta || {},
    alias: deniedXfqEj27OhMMeta?.alias || [],
    redirect: deniedXfqEj27OhMMeta?.redirect,
    component: () => import("/codebuild/output/src502701600/src/form.winx.ai/pages/[[research]]/denied.vue").then(m => m.default || m)
  },
  {
    name: finishGCTSvWpQh1Meta?.name ?? "research-finish",
    path: finishGCTSvWpQh1Meta?.path ?? "finish",
    meta: finishGCTSvWpQh1Meta || {},
    alias: finishGCTSvWpQh1Meta?.alias || [],
    redirect: finishGCTSvWpQh1Meta?.redirect,
    component: () => import("/codebuild/output/src502701600/src/form.winx.ai/pages/[[research]]/finish.vue").then(m => m.default || m)
  },
  {
    name: interactionBJ7GBFSmt4Meta?.name ?? "research-interaction",
    path: interactionBJ7GBFSmt4Meta?.path ?? "interaction",
    meta: interactionBJ7GBFSmt4Meta || {},
    alias: interactionBJ7GBFSmt4Meta?.alias || [],
    redirect: interactionBJ7GBFSmt4Meta?.redirect,
    component: () => import("/codebuild/output/src502701600/src/form.winx.ai/pages/[[research]]/interaction.vue").then(m => m.default || m)
  },
  {
    name: start1X1VT5O1LBMeta?.name ?? "research-start",
    path: start1X1VT5O1LBMeta?.path ?? "start",
    meta: start1X1VT5O1LBMeta || {},
    alias: start1X1VT5O1LBMeta?.alias || [],
    redirect: start1X1VT5O1LBMeta?.redirect,
    component: () => import("/codebuild/output/src502701600/src/form.winx.ai/pages/[[research]]/start.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexzpuE8azrOrMeta?.name ?? "index",
    path: indexzpuE8azrOrMeta?.path ?? "/",
    meta: indexzpuE8azrOrMeta || {},
    alias: indexzpuE8azrOrMeta?.alias || [],
    redirect: indexzpuE8azrOrMeta?.redirect,
    component: () => import("/codebuild/output/src502701600/src/form.winx.ai/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginRT5tQdtFrgMeta?.name ?? "login",
    path: loginRT5tQdtFrgMeta?.path ?? "/login",
    meta: loginRT5tQdtFrgMeta || {},
    alias: loginRT5tQdtFrgMeta?.alias || [],
    redirect: loginRT5tQdtFrgMeta?.redirect,
    component: () => import("/codebuild/output/src502701600/src/form.winx.ai/pages/login.vue").then(m => m.default || m)
  },
  {
    name: redirectYeI1FNxj37Meta?.name ?? "redirect",
    path: redirectYeI1FNxj37Meta?.path ?? "/redirect",
    meta: redirectYeI1FNxj37Meta || {},
    alias: redirectYeI1FNxj37Meta?.alias || [],
    redirect: redirectYeI1FNxj37Meta?.redirect,
    component: () => import("/codebuild/output/src502701600/src/form.winx.ai/pages/redirect.vue").then(m => m.default || m)
  }
]